import { Flex, Space, Spin, Typography } from "antd";
import { Header } from "./components/Header";
import { useEffect, useState } from "react";
import { BASE_URL } from "./constant";
import { Rating } from "./components/Rating";
import { ConfirmRating } from "./components/ConfirmRating";
import { Services } from "./components/Services";
import { ConfirmService } from "./components/ConfirmService";
import { ThankYou } from "./components/ThankYou";
import { Toilets } from "./components/Toilets";

const getServerData = async (code) => {
  const response = await fetch(`${BASE_URL}/web/service/${code}`);
  return await response.json();
}

const postFeedback = async (code, data) => {
  const response = await fetch(`${BASE_URL}/web/service/${code}/feedback`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  return await response.json();
}

const postService = async (code, data) => {
  const response = await fetch(`${BASE_URL}/web/service/${code}/cleaning`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  return await response.json();
}
let clearTimmer = -1;


function App() {
  const url = new URL(window.location.href); // Get current URL
  const serviceCode = url.searchParams.get('site');
  const [service, setService] = useState({});
  const [serviceLoading, setServiceLoading] = useState(true);
  const [toiletType, setToilet] = useState(-1);
  const [confirmService, setConfirmService] = useState({ service: null, check: 0 });
  const [confirmRating, setConfirmRating] = useState({ rating: null, check: 0 });
  const selectRatingConfirm = (rating) => {
    setConfirmRating(pre => {
      return {
        rating,
        check: (pre.check + 1)
      }
    })
  }

  const selectToilet = (toilet) => {
    setToilet(toilet.value)
  }

  const conRating = (check) => {
    setConfirmRating(pre => {
      return {
        ...pre,
        check: check ? 2 : 0
      }
    })
  }
  const conService = (check) => {
    setConfirmService(pre => {
      return {
        ...pre,
        check: check ? pre.check + 1 : pre.check - 1
      }
    })
  }
  const selectServiceConfirm = (service) => {
    if (service === true) {
      setConfirmService(pre => {
        return {
          ...pre,
          check: (pre.check + 1)
        }
      })
    } else if (service === false) {
      setConfirmService(pre => {
        return {
          ...pre,
          check: (pre.check - 1)
        }
      })
    } else {
      setConfirmService(pre => {
        return {
          service,
          check: (pre.check + 1)
        }
      })
    }
  }
  useEffect(() => {
    getServerData(serviceCode).then((data) => {
      console.log(data)

      setService(data)
      if (data?.toilets?.length === 1) {
        setToilet(data.toilets[0].value)
      }
      if (data?.toilets?.length === 0) {
        setToilet(null)
      }
      
    }).finally(
      () => setServiceLoading(false)
    )
  }, [])



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await getServerData(serviceCode);
  //       console.log(data);

  //       setService(data);

  //       if (data?.toilets?.length === 1) {
  //         setToilet(data.toilets[0].value);
  //       } else {
  //         setToilet(null);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setServiceLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [serviceCode]); 




  
  useEffect(() => {
    if (clearTimmer === null) {
      clearTimmer = -1
    } else {
      clearTimmer = 10
    }

  }, [confirmRating, confirmService, toiletType])

  useEffect(() => {
    if (confirmRating.check == 2) {
      postFeedback(serviceCode, {
        bathroom: serviceCode,
        toilet_type: toiletType,
        rating: confirmRating.rating.value
      })
    }
  }, [confirmRating.check])

  useEffect(() => {
    if (confirmService.check == 3) {
      postService(serviceCode, {
        bathroom: serviceCode,
        toilet_type: toiletType,
        service_id: confirmService.service.id
      })
    }
  }, [confirmService.check])

  const resetState = () => {
    setConfirmRating({ rating: null, check: 0 })
    setConfirmService({ service: null, check: 0 })
    if (service?.toilets?.length === 1) {
      setToilet(service?.toilets[0]?.value)
    } else if (service?.toilets?.length === 0) {
      setToilet(null)
    } else {
      setToilet(-1)
    }
    clearTimmer = null
  }

  if (!service) {
    return <Flex gap="middle" vertical justify="center" align="center" style={containerStyles}>
      <Flex vertical style={sectionStyles}>
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      </Flex>
    </Flex>
  }

  if (serviceLoading) {
    return <Flex gap="middle" vertical justify="center" align="center" style={containerStyles}>
      <Flex vertical style={sectionStyles} justify="center" align="center" >
        <Typography.Title level={3}>Loading...</Typography.Title>
      </Flex>
    </Flex>
  }

  
  if (!service.bathroomTitle) {
    return <Flex gap="middle" vertical justify="center" align="center" style={containerStyles}>
      <Flex vertical style={sectionStyles} justify="center" align="center" >
        <Typography.Title level={3} style={{ color: "red" }}>This service does not exist</Typography.Title>
      </Flex>
    </Flex>
  }

  return (
    <Flex gap="middle" vertical justify="center" align="center" style={containerStyles}>
      <Flex vertical style={sectionStyles}>
        <Header bathroom={service?.bathroomTitle} title={service?.siteTitle ?? "Loading ..."} lastTime={service?.lastTime} />
        {toiletType === -1 && (
          <Toilets toilets={service?.toilets} confirm={selectToilet} />
        )}
        {toiletType != -1 && confirmService.check == 0 && (
          <Space direction="vertical" style={{ padding: 10 }} align="center">
            {confirmRating.check == 0 && <Rating lastTime={service?.lastTime} ratings={service?.ratings} confirm={selectRatingConfirm} />}
            {confirmRating.check == 1 && <ConfirmRating rating={confirmRating.rating} confirm={conRating} />}
            {confirmRating.check == 2 && <ThankYou title={"Thank you for your feedback!"} />}
          </Space>
        )}
        {toiletType != -1 && confirmRating.check != 1 && (
          <Space direction="vertical" style={{ padding: 10 }} align="center">
            {confirmService.check < 2 && <Services services={service?.services} confirm={selectServiceConfirm} state={confirmService.check} />}
            {confirmService.check == 2 && <ConfirmService service={confirmService.service} confirm={conService} />}
            {confirmService.check == 3 && <ThankYou title={"Your service request has been saved!"} />}
          </Space>
        )}
      </Flex>
    </Flex>
  );
}

export default App;

const containerStyles = {
  padding: "20px 16px",
  backgroundColor: "#ffffff",
  minHeight: "calc(100dvh - 40px)",
  borderRadius: "8px"
}

const sectionStyles = {
  padding: 20,
  // backgroundColor: "#f5f5f5",
  maxWidth: "768px",
  width: "calc(100% - 34px)"
}