import { Space, Flex } from "antd";
import { ToiletItem } from "./ToiletItem"

export const Toilets = ({ toilets, confirm }) => {
    return (
       <Flex wrap="wrap" justify="center" gap={20}>
            { toilets?.map(e => {
                return <a role="button" onClick={() => confirm(e)}>
                    <ToiletItem toilet={e} />
                </a>
            }) }
       </Flex>
    )
}