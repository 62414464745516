import { Card, Space } from "antd"
const { Meta } = Card
export const ServiceItem = ({ service }) => {
    return <Space direction="vertical" align="center">
        <Card
            align="center"
            hoverable
            cover={
                <img 
                  alt={service.name} src={service.icon?.url} style={imgStyles} />
            }
        >
            <Meta title={service.name} />
        </Card>
    </Space>
}

const imgStyles = { 
    width: 80, 
    height: 80, 
    padding: "20px", 
    // objectFit: "contain" 
}