import { Avatar, Space, Typography } from "antd";
const { Title } = Typography;

export const Header = ({ title, bathroom }) => {
    return (
        <Space direction="vertical" align="center">
            <Title level={4} style={{ marginTop: 0, marginBottom: "0.25em" }}>{bathroom}</Title>
            <Typography>{title}</Typography>
            <img
                style={{ margin: "20px auto", flexShrink: 0 }}
                width={300}
                alt={title}
                src="./images/logo.png"
            />
        </Space>
    )
}