// export const BASE_URL = window.location.hostname === "localhost" ? "http://localhost:2056" : "http://dev.rewasoft.com.np:2056";

// export const BASE_URL = "http://dev.rewasoft.net:2056";
export const BASE_URL = "https://api-kiosk.springmounttech.au";

export const RatingType = {
    Very_Poor: "Very Poor",
    Poor: "Poor",
    Average: "Average",
    Good: "Good",
    Excellent: "Excellent"
}