import { Button, Space, Flex } from "antd"
import { ServiceItem } from "./ServiceItem"

export const Services = ({ services, confirm, state }) => {
    return (
        <Space direction="vertical" align="center">
            {state == 0 && (
                <Button style={{ backgroundColor: "black", color: "#fff" }} onClick={() => confirm(true)} block>Request Service</Button>
            )}
            {state == 1 && (
                <>
                    <Flex gap={20} wrap="wrap" justify="center" style={{ marginBottom: 24 }}>
                        {services?.map(e => {
                            return <a key={e.id} role="button" onClick={() => confirm(e)}>
                                <ServiceItem service={e} />
                            </a>
                        })}
                    </Flex>
                    <Button style={{ backgroundColor: "darkgrey",color: "#fff" }} onClick={() => confirm(false)} block>Go Back</Button>
                </>
            )}
        </Space>
    )
}