import { Button, Flex, Space, Typography } from "antd"
import { RateItem } from "./RateItem"
import { ServiceItem } from "./ServiceItem"
const { Title } = Typography

export const ConfirmService = ({ service, confirm }) => {
    return (
        <Space direction="vertical" align="center">
            <Space size={16} wrap direction="vertical" align="center">
                <ServiceItem service={service} />
                <Title level={5}>Confirm your service request!!!</Title>
            </Space>
            <Space size={16} wrap>
                <Flex gap="small" wrap="wrap">
                    <Button style={{ backgroundColor: "black",color: "#fff" }} type="primary" onClick={() => confirm(true)}>Confirm</Button>
                    <Button color="lightgrey" onClick={() => confirm(false)}>Cancel</Button>
                </Flex>
            </Space>
        </Space>
    )
}