import { Flex, Space, Typography } from "antd"
import { RateItem } from "./RateItem"

const { Text } = Typography

export const Rating = ({ lastTime, ratings, confirm }) => {
    return (
        <Space direction="vertical" align="center">
            <Text style={{ textAlign: "center", display: "block" }}>Last serviced at <br />{lastTime}</Text>
            <Text strong style={{ padding: "8px 0 24px", display: "inline-block" }}>Please rate this space</Text>
            <Flex wrap="wrap" justify="center" gap={24} style={ sectionStyles }>
                {ratings?.map((e,i) => 
                   <a role="button" onClick={() => confirm(e)} key={ "rate-type-" + i }>
                      <RateItem rating={e} />
                   </a>
                )}
            </Flex>
        </Space>
    )
}

const sectionStyles = { 
    backgroundColor: "#ffffff",
    padding: 24,
    borderRadius: 6
}